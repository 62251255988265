 .center-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
}

.form-signin .form-group .form-control {
  margin-bottom: 10px;
  width: 100%;
  border-radius: 0 ;
}

.loading {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
